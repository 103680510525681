import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/reducers'
import { CButton, CFormInput, CFormLabel } from '@coreui/react-pro'
import { useEffect, useState } from 'react'
import axios from '../../../../shared/config/axios-interceptor'
import { ToastSuccess } from '@/components/shared/toast/Toast'
import { useRouter } from '@/shared/utils/hooks/useRouter'
import { AppDispatch } from '@/store'
import { getProfile } from '../../auth/auth.api'
import Select, { SingleValue } from 'react-select'
import { bankSelectors } from '../../BankManagement/banksManagement.reducer'
import { getEntities as getEntitiesBank } from '../../BankManagement/banksManagement.api'
import { ISelectValue } from '@/shared/shared-interfaces'
import { IBank } from '@/shared/model/bank.model'
import { CustomOption, CustomSingleValue } from '../../UserManagement/UserUpdate'
const TaiKhoanNganHang = () => {
  const { navigate } = useRouter()
  const dispatch = useDispatch<AppDispatch>()

  const { user } = useSelector((state: RootState) => state.authentication)

  const [cardName, setCardName] = useState(user?.cardName || '')
  const [cardNumber, setCardNumber] = useState('')
  const [bankName, setBankName] = useState(user?.bankId || 0)

  const onChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (index === 1) {
      setCardName(value)
    }
    if (index === 2) {
      setCardNumber(value)
    }
    // if (index === 3) {
    //   setBankName(value)
    // }
  }

  const onSubmit = async () => {
    try {
      const response = await axios.put('/user/' + user?.id, {
        cardName,
        cardNumber,
        bankId: bankName,
      })
      if(response){
        ToastSuccess('Cập nhật ngân hàng thành công')
        dispatch(getProfile())
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const returnBankItem = (items: IBank[], selectBank?: IBank | null) => {
    return items.map((item) => {
      return {
        value: item.id,
        label: item.name,
        image: item.avatar,
      }
    })
  }

  useEffect(() => {
    dispatch(getEntitiesBank({ page: 1, limit: 100 }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const returnOption = (
    optionValue: number | null | undefined,
    array: ISelectValue<string>[],
  ) => {
    if (!optionValue) return null
    if (!array.length) return null
    return array.find(({ value }) => Number(value) === Number(optionValue))
  }
  const banks = useSelector(bankSelectors.selectAll)
  const customBankItems: any = returnBankItem(banks)

  return (
    <div className="p-4" style={{ background: "rgb(56, 65, 82)" }}>
        <h3 className="text-white">Tài khoản ngân hàng</h3>
        <div className="box-form">
        <div className="form-group-custom mt-3">
            <CFormLabel
              htmlFor="bankId"
              className="text-white"
              style={{ fontSize: 14 }}
            >
            Ngân hàng
            </CFormLabel>
            <Select
              defaultValue={null}
              className="custom-select form-height-44"
              classNamePrefix="react-select"
              value={
                returnOption(
                  bankName as any,
                  customBankItems,
                ) as any
              }
              onChange={(newValue: SingleValue<ISelectValue<string>>) => {
                setBankName(Number(newValue?.value) || 0)
              }}
              noOptionsMessage={() => <>Không có ngân hàng nào</>}
              id={'bankId'}
              options={customBankItems}
              placeholder={'Ngân Hàng'}
              isClearable={true}
              closeMenuOnSelect={true}
              menuPosition={'absolute'}
              name="bankId"
              components={{
                Option: CustomOption,
                SingleValue: CustomSingleValue,
              }}
            />
          </div>

          <div className="form-group-custom mt-3">
            <CFormLabel
              htmlFor="cardNumber"
              className="text-white"
              style={{ fontSize: 14 }}
            >
              Số tài khoản
            </CFormLabel>
            <CFormInput
              onChange={(e) => onChange(2, e)}
              type="text"
              id="cardNumber"
              placeholder={user?.cardNumber ? user?.cardNumber?.toString().replace(/(\d{2})\d{5}/, '$1*****') : ''}
              value={cardNumber}
              style={{ fontSize: 14 }}
            />
          </div>


          <div className="form-group-custom mt-3">
            <CFormLabel
              htmlFor="cardName"
              className="text-white"
              style={{ fontSize: 14 }}
            >
              Chủ tài khoản
            </CFormLabel>
            <CFormInput
              onChange={(e) => onChange(1, e)}
              type="text"
              id="cardName"
              value={cardName}
              style={{ fontSize: 14 }}
            />
          </div>


          <CButton
            disabled={
                user?.bankId && user?.cardNumber && user?.cardName
                    ? true
                    : false
                }
          onClick={onSubmit} className="mt-4 w-100" style={{ fontSize: 14, background: "rgb(183, 244, 227)", border: "none", color: "#000", boxShadow: "none"}}>LƯU THÔNG TIN</CButton>


        </div>
      </div>
  )
}

export default TaiKhoanNganHang
